import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import display from "@jvm/common/both.common.module.css";
import display_mobile from "@jvm/common/mobile.common.module.css"
import {_download_link, _obf_email} from "@jvm/common";
import "./site_intro.css";
import throttle from "lodash.throttle";
import {NavLink} from "react-router-dom";
import imgGoldfish from "./goldfish.png"
import imgBagLady from "./baglady.png"
import imgTitle from "./needjobtitleimg.png"
import imgPdfDownload from "./pdfdownload.png"
import {What_Job_Do_You_Want, Why_No_Job} from "./intro_text";


/**
 * Check if an element is in viewport

 * @param {number} offset - Number of pixels up to the observable element from the top
 * @param {number} throttleMilliseconds - Throttle observable listener, in ms
 */
function useVisibility<Element extends HTMLElement>(
    offset: number = 0,
    throttleMilliseconds: number = 100
): [Boolean, React.MutableRefObject<Element>] {
    const [isVisible, setIsVisible] = useState(true);
    const currentElement = useRef() as MutableRefObject<Element>;

    const onScroll = throttle(() => {
        if (!currentElement.current) {
            setIsVisible(false);
            return;
        }
        const top = currentElement.current.getBoundingClientRect().top;
        setIsVisible(top + offset >= 0 && top - offset <= window.innerHeight);
    }, throttleMilliseconds);

    useEffect(() => {
        document.addEventListener('scroll', onScroll, true);
        return () => document.removeEventListener('scroll', onScroll, true);
    });

    return [isVisible, currentElement];
}

function useMobileVisibility<Element extends HTMLElement>(
    offset: number = 0,
    throttleMilliseconds: number = 100
): [Boolean, React.MutableRefObject<Element>] {
    const [mobileIsVisible, mobileSetIsVisible] = useState(true);
    const mobileCurrentElement = useRef() as MutableRefObject<Element>;

    const onScroll = throttle(() => {
        if (!mobileCurrentElement.current) {
            mobileSetIsVisible(false);
            return;
        }
        const top = mobileCurrentElement.current.getBoundingClientRect().top;
        mobileSetIsVisible(top + offset >= 0 && top - offset <= window.innerHeight);
    }, throttleMilliseconds);

    useEffect(() => {
        document.addEventListener('scroll', onScroll, true);
        return () => document.removeEventListener('scroll', onScroll, true);
    });

    return [mobileIsVisible, mobileCurrentElement];
}

export function SiteHeader() {

    const [isVisible, currentElement] = useVisibility<HTMLImageElement>(100);
    const [mobile_isVisible, mobile_currentElement] = useMobileVisibility<HTMLImageElement>(100);

    return (
        <div>
            <div className={display.showOnMobileOnly}>
                <div className={display_mobile.mobile_header_flex_container}>
                    <div className={display_mobile.mobile_header_text}>
                        <NavLink to={"/"}><img className={display_mobile.mobile_bagImg} alt={"Lady with paper bag on her head."}
                                               src={imgBagLady}/></NavLink>

                        <div style={{display: "flex", flexDirection: "column"}}>
                            <NavLink to={"/"}><img className={display_mobile.mobile_titleImg}
                                                   alt={"I am Jennifer Murdoch and I need a job."}
                                                   src={imgTitle}/></NavLink>

                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                flexGrow: 1,
                                margin: "0 3.5vw 2vw 0",
                                justifyContent: "right"
                            }}>
                                <div>
                                    {_download_link(<img ref={mobile_currentElement}
                                                         className={[mobile_isVisible ? "mobile_pdfDownloadAnimate":"pdfDownload", display_mobile.mobile_pdfDownloadPadding, display_mobile.mobile_pdfDownloadHideOnPrint].join(' ')}
                                                         alt={"Download PDF Version"} src={imgPdfDownload}
                                                         height={"40vw"}/>)}
                                </div>
                                <div className={display_mobile.mobile_header_textdiv}>
                                    <p>{_obf_email()}</p>
                                    <p>&#123;&nbsp;&nbsp;<a href={"http://www.why-cant-i-find-a-job.org"}
                                                            target={"_blank"}
                                                            rel="noreferrer">Why-Cant-I-Find-A-Job.org</a>&nbsp;&nbsp;&#125;
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={display.showFullLayout}>
                <div className={"header_flex_container"}>
                    <div className={"header_text"}>
                        <NavLink to={"/"}><img className={"bagImg"} alt={"Lady with paper bag on her head."}
                                               src={imgBagLady}/></NavLink>

                        <div>
                            <NavLink to={"/"}><img className={"titleImg"}
                                                   alt={"I am Jennifer Murdoch and I need a job."}
                                                   src={imgTitle}/></NavLink>
                            <p>
                                &#123;&nbsp;&nbsp;{_obf_email()}
                                &nbsp;&nbsp;&nbsp; ~ &nbsp;&nbsp; <a href={"http://www.why-cant-i-find-a-job.org"}
                                                                     target={"_blank"}
                                                                     rel="noreferrer">Why-Cant-I-Find-A-Job.org</a>&nbsp;&nbsp;&#125;
                            </p>
                        </div>

                        <div>
                            {_download_link(<img ref={currentElement}
                                                 className={[isVisible ? "pdfDownloadAnimate":"pdfDownload", "pdfDownloadPadding", "pdfDownloadHideOnPrint"].join(' ')}
                                                 alt={"Download PDF Version"} src={imgPdfDownload} height={"45px"}/>)}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default function SiteIntro() {

    return (
        <div>
            <div className={display.showOnMobileOnly}>
                <div className={"setDarkBgColor"} style={{paddingBottom: "15px", marginTop: 0}}>
                    <div className={display_mobile.mobile_section}>
                        <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_title_text].join(' ')}>
                            <div className={[display_mobile.title, "intro_title"].join(' ')}>Why Can't I Find a Job?</div>
                        </div>
                        <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_subtitle_text].join(' ')}>
                            I DON'T KNOW...&nbsp;&nbsp;&nbsp;<span
                            className={"showOnPrint"}>( <span>I need work... badly.</span> )</span>
                        </div>
                        <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_content_text].join(' ')}>
                            <Why_No_Job/>
                        </div>
                    </div>

                    <div className={display_mobile.mobile_section}>
                        <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_title_text].join(' ')}>
                            <div className={[display_mobile.title, "intro_title"].join(' ')}>What kind of job are you looking for?</div>
                        </div>
                        <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_subtitle_text].join(' ')}>
                            VIRTUALLY ANYTHING AT THIS POINT.<br/><span
                            className={"hideOnPrint"}>( <span>I have preferences for job titles, but simply put, I cannot afford to picky.</span> )</span>
                        </div>
                        <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_content_text].join(' ')}>
                            <What_Job_Do_You_Want/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={display.showFullLayout}>
                <div className={"setDarkBgColor"}>
                    <div className="intro_flex_container">
                        <div className="resume_headers">
                            <div className="resume_header">
                                <div className="header_loc intro_header_loc">
                                    <div className="title intro_title">Why Can't I Find a Job?</div>
                                </div>
                            </div>
                        </div>
                        <div className="sections">
                            <div className="section" style={{paddingTop: 0}}>
                                <div className="heading">
                                    I DON'T KNOW.&nbsp;&nbsp;&nbsp;&nbsp;<span className={"showOnPrint"}>( <span>I need work... badly.</span> )</span>
                                </div>
                                <div className="content_col">
                                    <div className="content_box">
                                        <div className="blue_div"></div>
                                        <div className="content">
                                            <Why_No_Job/>
                                        </div>
                                    </div>
                                    <div className="footer"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro_flex_container">
                        <div className="resume_headers">
                            <div className="resume_header">

                                <div className="header_loc intro_header_loc">
                                    <div className="title intro_title">What kind of job are you looking for?</div>
                                </div>
                            </div>
                        </div>
                        <div className="sections">
                            <div className="section" style={{paddingTop: 0}}>
                                <div className="heading">
                                    VIRTUALLY ANYTHING AT THIS POINT.&nbsp;&nbsp;&nbsp;&nbsp;<span
                                    className={"hideOnPrint"}>( <span>I have preferences for job titles, but simply put, I cannot afford to picky.</span> )</span>
                                </div>
                                <div className="content_col">
                                    <div className="content_box">
                                        <div className="blue_div"></div>
                                        <div className="content">
                                            <What_Job_Do_You_Want/>
                                        </div>
                                    </div>
                                    <div className="footer"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                        height: "15px"
                    }}>&nbsp;</div>
                </div>
            </div>
        </div>
    );
}

export function SiteFooter() {

    return (
        <div>
            <div className={display.showOnMobileOnly}>
                <div className={display_mobile.mobile_footer_box}>
                    <div className={display_mobile.mobile_footer_text}>
                        <NavLink to={"/"}><img alt={"Goldfish"} src={imgGoldfish} height={"80vw"}/></NavLink>
                        <div className={display_mobile.footer_copyright}>
                            <div>Rendered in {(new Date().getFullYear())}.</div>
                            <div>Jennifer Murdoch<br/>&#123;&nbsp;{_obf_email()}&nbsp;&#125;
                            </div>

                            <div style={{marginTop: "15px"}}><NavLink to={"/acknowledge"}
                                                                      style={{color: "#ca9af1"}}>Acknowledgements</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={display.showFullLayout}>
                <div className="footer_flex_container">
                    <div className={"footer_text"}>
                        <NavLink to={"/"}><img alt={"Goldfish"} src={imgGoldfish} width={"100px"}/></NavLink>
                        <div className={"footer_copyright"}>
                            <div>Rendered in {(new Date().getFullYear())}.</div>
                            <div>Jennifer Murdoch&nbsp;&nbsp;&nbsp;&#123;&nbsp;{_obf_email()}&nbsp;&#125;
                            </div>

                            <div style={{marginTop: "15px"}}><NavLink to={"/acknowledge"}
                                                                      style={{color: "#ca9af1"}}>Acknowledgements</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
