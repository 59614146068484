import React from 'react';
import reactStringReplace from 'react-string-replace';
import "../../layout/v1/page_resume.css";
import "./resume_formatting.css"
import {NavHashLink} from "react-router-hash-link";

export const SecExperience = ({mobile = false}) => {

    const ref_sw_text = 'Software Development + Project Management';
    const ref_wc_text = 'Writing + Communications + Small Business Marketing';
    const ref_ea_text = 'Entrepreneurship + Administration + Planning';
    const ref_tt_text = 'Teaching + Training';
    const ref_cs_text = 'Customer Service + Hospitality';

    return (
        <div>
            <h4>Content Shortcuts</h4>
            <ul className={"toc"}>
                <li><NavHashLink smooth to={mobile ? '#mobile_ref_sw' :'#ref_sw'}>{ref_sw_text}</NavHashLink></li>
                <li><NavHashLink smooth to={mobile ? '#mobile_ref_wc' : "#ref_wc"}>{ref_wc_text}</NavHashLink></li>
                <li><NavHashLink smooth to={mobile ? '#mobile_ref_tt' :"#ref_tt"}>{ref_tt_text}</NavHashLink></li>
                <li><NavHashLink smooth to={mobile ? '#mobile_ref_ea' :"#ref_ea"}>{ref_ea_text}</NavHashLink></li>
                <li><NavHashLink smooth to={mobile ? '#mobile_ref_cs' :"#ref_cs"}>{ref_cs_text}</NavHashLink></li>
            </ul>

            <div className="end_spacer display_on_print"></div>
            <div className="page_break display_on_print"></div>
            <div className="spacer display_on_print"></div>

            <ul className="ui--diamond">
                <li>
                    <h3 id={mobile ? 'mobile_ref_sw' : "ref_sw"}>{reactStringReplace(ref_sw_text, '+', () => (
                        <span> and </span>
                    ))}</h3>
                    <ul className={"ui--no_bullet"}>
                        <li><span>Employment Experience</span>:
                            <ul>
                                <li>Checkfront Inc (<span>Software Development Team Lead</span>)</li>
                                <li>Racerocks Inc (<span>Project Manager</span>)</li>
                                <li>Vivacity Tech (<span>Business Sole Proprietor/Consultant</span>)</li>
                                <li>Tutela Technologies Inc. (<span>Project Lead, Software Developer</span>)</li>
                                <li>TC Helicon Vocal Technologies (<span>Mobile App Developer</span>)</li>
                                <li>LOUD Technologies Inc (<span>Desktop and Mobile App Development</span>)</li>
                            </ul>
                        </li>
                        <li><span>Project Management Skills</span>:
                            <ul className={"ui--no_bullet"}>
                                <li><span>Responsibilities</span>: Requirements gathering, Project documentation
                                    writing
                                    and maintenance, Internal and customer issue tracking, Project time and progress
                                    tracking, Team "leadership" and performance "reviews"
                                </li>
                                <li><span>Methodologies</span>: Agile/Scrum (incl. creation and writing of personas,
                                    user stories, and use cases) and Waterfall approaches,
                                    Test-driven development (TDD), Source version control, Test case writing and
                                    manual
                                    test case management
                                </li>
                                <li><span>Software Tools</span>: Issue tracking (e.g. Atlassian JIRA, Bugzilla,
                                    Trello),
                                    Documentation management (e.g. Atlassian Confluence), Collaboration and planning
                                    (e.g. Miro, Notion),
                                    Source version control (Git, SVN) and source control hosting platforms
                                    (BitBucket,
                                    GitHub),
                                    Microsoft 365 Suite (Incl. MS Teams, MS Project, MS Excel)
                                </li>
                            </ul>
                        </li>
                        <li><span>Technical Skills</span>:
                            <ul className={"ui--no_bullet"}>
                                <li><span>Target Platforms</span>: mobile (iOS + Android), web (apps + APIs),
                                    cloud
                                </li>
                                <li><span>Development Languages/Frameworks</span>: JavaScript/TypeScript and
                                    Node.js,
                                    Java,
                                    Python, C#/.NET, Swift and
                                    Objective-C, C++, C
                                </li>
                                <li><span>Web Front-End Dev</span>: React, MaterialUI, jQuery, HTML and CSS,
                                    UI/UX design with Figma, QT and Juce C++ frameworks
                                </li>
                                <li><span>Databases</span>: Various relational (Postgres, Oracle, mySQL) and
                                    No-SQL/key-value stores, advanced SQL, ElasticSearch
                                </li>
                                <li><span>Cloud</span>: Google (incl. BigQuery, Dataflow, Cloud Storage, Data
                                    Studio), AWS (incl. Amplify, Lightsail, Route53, SES, Lambda,
                                    DynamoDB)
                                </li>
                                <li><span>“DevOps”</span>: Scripting, “infrastructure as code”, fundamentals of
                                    cloud
                                    infrastructure architecture and management: Docker, Kubernetes, and Terraform
                                </li>
                                <li><span>Embedded Dev</span>: Academic RTOS fundamentals and embedded linux</li>
                                <li><span>Tools</span>: Integrated development environments (IDE) (various incl.
                                    JetBrains Webstorm, JetBrains PyCharm, JetBrains IntelliJ IDEA, Eclipse, Apple
                                    XCode, Microsoft Visual Studio),
                                    source version control (Git, SVN) and source control hosting platforms (GitHub,
                                    BitBucket), virtual machines (VM)
                                </li>
                                <li><span>Data Analysis/Science</span>: Google Cloud Services
                                    (BigQuery, Dataflow, Cloud Storage, Data
                                    Studio), Jupyter Notebooks, OmniSci, Weka,
                                    Matlab, fundamentals of R, MS Excel, MS Access,
                                    advanced SQL
                                </li>
                                <li><span>Graphic/UI/UX Design and Content Creation</span>: UI/UX design with Figma,
                                    Adobe (Photoshop, Illustrator, Audition), Gimp, icon design, technical writing
                                    (user/developer manuals, UI copy writing)
                                </li>
                                <li><span>Quality Assurance</span>: Code reviews and pull requests (PR), test-driven
                                    development (TDD), unit testing, automated integration and user interface
                                    testing,
                                    continuous integration and deployment (CI/CD) systems, test case writing and
                                    manual
                                    test case management, issue tracking software (e.g. Atlassian JIRA, Bugzilla,
                                    Trello)
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>


                <div className="end_spacer display_on_print"></div>
                <div className="page_break display_on_print"></div>
                <div className="spacer display_on_print"></div>

                <li><h3 id={mobile ? 'mobile_ref_wc' : "ref_wc"}>{reactStringReplace(ref_wc_text, '+', () => (
                    <span> and </span>
                ))}</h3>
                    <ul className={"ui--no_bullet"}>
                        <li><span>Employment Experience</span>:
                            <ul>
                                <li>Vivacity Tech
                                    (<span>Business Sole Proprietor, Principal Marketing and Technical Consultant</span>)
                                </li>
                                <li>Très Gourmet Bakery and Cafe (<span>Marketing</span>)</li>
                                <li>Various <a href={"#ref_sw"}>software development companies</a> (<span>Software Developer, Team Lead, Project Manager</span>)
                                </li>
                                <li>Personal creative projects (<span>Journaling and Online Blog Writing, 2 poetry
                                    books</span>)
                                </li>
                                <li>Vancouver Folk Music Festival (<span>Stage Crew, Artist Check-in Booth</span>)</li>
                                <li>Faculty of Computer Science, University of Victoria (<span>Graduate Student and Research Assistant</span>)
                                </li>
                                <li>Faculty of Computer Science, University of Victoria (<span>Course Instructor: 1st-Year Level</span>)
                                </li>
                                <li>Faculty of Computer Science, University of Victoria (<span>Teaching Assistant and Lab Instructor</span>)
                                </li>
                            </ul>
                        </li>
                        <li><span>Skills</span>:
                            <ul className={"ui--no_bullet"}>
                                <li><span>Academic</span>: Authoring/editing of multiple research-based
                                    peer-reviewed academic publications, teaching and evaluation materials,
                                    lectures/seminars/presentations
                                </li>
                                <li><span>Technical writing</span>: software user/developer manuals, user
                                    interface copy and translation management
                                </li>
                                <li><span>Marketing (copy writing, graphic design, digital marketing)</span>: Small
                                    business branding, website design and copy writing,
                                    digital advertising (design and copy) and various online advertising platforms,
                                    design of print marketing materials
                                    (business cards, brochures, etc.), visual presentation design and
                                    presentation/seminar/lecture delivery
                                </li>
                                <li><span>Website design and web application development</span>: Various
                                    marketing/blogging content management systems (CMS)
                                    (e.g. Wordpress, Ghost), Wix and Squarespace, React, JavaScript/Typescript +
                                    Node.js, MaterialUI, CSS, APIs (design and implementation), search engine
                                    optimization (SEO), various website and DNS hosting platforms.
                                </li>
                                <li><span>Podcasting</span>: Recording, audio editing (Adobe Audition, Audacity),
                                    graphic design, and
                                    analytics
                                </li>
                                <li><span>Content creation and graphic design</span>: Adobe Suite (Photoshop,
                                    Illustrator, Audition), Gimp, Canva, Software UI/UX design with Figma, Audacity,
                                    various podcast-hosting platforms
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>


                <li><h3 id={mobile ? 'mobile_ref_tt' : "ref_tt"}>{reactStringReplace(ref_tt_text, '+', () => (
                    <span> and </span>
                ))}</h3>
                    <ul className={"ui--no_bullet"}>
                        <li><span>Employment Experience</span>:
                            <ul>
                                <li>Faculty of Computer Science, University of Victoria (<span>Course Instructor: 1st-year University Computer Science</span>)
                                </li>
                                <li>Faculty of Computer Science, University of Victoria (<span>Teaching Assistant and Lab Instructor</span>)
                                </li>
                                <li>Women in Computer Science (WECS) Chapter, University of Victoria (<span>Primary and Secondary School C.S. Outreach Educator</span>)
                                </li>
                                <li>Faculty of Computer Science Learning Centre, Dalhousie University (<span>Learning Centre Tutor</span>)
                                </li>
                                <li>SuperNOVA, Dalhousie University (<span>Elementary School Computer Science Outreach Educator</span>)
                                </li>
                            </ul>
                        </li>


                        <div className="end_spacer display_on_print"></div>
                        <div className="page_break display_on_print"></div>
                        <div className="spacer display_on_print"></div>


                        <li><span>Skills</span>:
                            <ul className={"ui--no_bullet"}>
                                <li><span>Lectures / seminars / presentations</span>: Design and delivery to audiences of all sizes (ages:
                                    adult/university-level, high school, and elementary school)</li>
                                <li><span>Curriculum development</span>: Planning, creation, and grading of assignments, projects, and exams</li>
                                <li><span>Student welfare</span>: Personalized one-on-one tutoring and extra help sessions, tracking student progress/grades</li>
                            </ul>
                        </li>
                    </ul>
                </li>


                <li><h3 id={mobile ? 'mobile_ref_ea' : "ref_ea"}>{reactStringReplace(ref_ea_text, '+', () => (
                    <span> and </span>
                ))}</h3>
                    <ul className={"ui--no_bullet"}>
                        <li><span>Employment Experience</span>:
                            <ul>
                                <li>Checkfront Inc (<span>Software Development Team Lead</span>)</li>
                                <li>Vivacity Tech
                                    (<span>Business Sole Proprietor, Principal Marketing and Technical Consultant</span>)
                                </li>
                                <li>Tutela Technologies Inc. (<span>Project Lead, Software Developer</span>)</li>
                                <li>City of Victoria Participatory Budgeting Steering Committee (<span>Steering Committee Member</span>)
                                </li>
                                <li>The “B” Ride and Lounge Party - Information evening & weekly cycling rides
                                    (<span>Principal Coordinator</span>)
                                </li>
                                <li>Faculty of Computer Science, University of Victoria (<span>Academic Conference Organizing Committee Member</span>)
                                </li>
                                <li>Faculty of Computer Science, University of Victoria (<span>Course Instructor: 1st-year University Computer Science</span>)
                                </li>
                                <li>AIESEC Victoria Chapter, University of Victoria (<span>Principal Organizer for Médecins Sans Frontières Speaker Night</span>)
                                </li>
                                <li>Various charity fundraising and event committees (<span>Event Planner</span>)
                                </li>
                                <li>Dalhousie University's Faculty of Computer Science Student Society (<span>Social Director, President</span>)
                                </li>
                            </ul>
                        </li>
                        <li><span>Skills</span>:
                            <ul className={"ui--no_bullet"}>
                                <li><span>Accounting</span>: Invoicing/payment software, project time tracking, and
                                    small business taxes
                                </li>
                                <li><span>Project Management</span>: See '<a href={"#ref_sw"}>Software Development +
                                    Project Management: Project Management Skills</a>'.
                                </li>
                                <li><span>Communications</span>: Documentation writing and maintenance, visual
                                    presentation design, delivering lectures/seminars/presentations to large
                                    audiences/classes, small business marketing (See
                                    '<a href={"#ref_wc"}>Writing + Communications + Small Business Marketing</a>')
                                </li>
                                <li><span>Event planning</span>: Social and recreational events, charity fundraisers
                                </li>
                                <li><span>Productivity software</span>: Customer relationship management (CRM)
                                    software,
                                    Google Workspace (Email + Productivity), MS 365 Suite (Incl. MS Teams, MS
                                    Project,
                                    MS Excel, MS Access)
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>


                <li><h3 id={mobile ? 'mobile_ref_cs' : "ref_cs"}>{reactStringReplace(ref_cs_text, '+', () => (
                    <span> and </span>
                ))}</h3>
                    <ul className={"ui--no_bullet"}>
                        <li><span>Employment Experience</span>:
                            <ul>
                                <li>Quality Inn (<span>Hotel Front Desk</span>), Cinecenta Cinema (<span>Box Office</span>)</li>
                                <li>Dicken's Pub House, Très Gourmet Cafe (<span>Food & Beverage Server</span>)</li>
                                <li>Laura Secord, Bernard Callebaut (<span>Retail Sales of Chocolate</span>)</li>
                                <li>Wellburn's Market, Fairway Market (<span>Grocery Store Cashier</span>)</li>
                            </ul>
                        </li>
                        <li><span>Skills</span>:
                            <ul className={"ui--no_bullet"}>
                                <li>Cash/card handling, point-of-sale systems (use + configuration), sales
                                    reporting,
                                    and billing and invoicing
                                </li>
                                <li>Answering phones and customer queries</li>
                                <li>Product gift wrapping, and product display</li>
                            </ul>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    );
}

