import React from 'react';
import {PageResume} from "./layout/v1/page_resume";
import "./layout/v1/page_resume.css"

export default function Resume() {
    return (
        <PageResume />
    );
}


