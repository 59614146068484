import React from 'react';
import {Acknowledge_Text} from "./acknowledge_text"
import {_bg_img} from "@jvm/common";
import display_mobile from "@jvm/common/mobile.common.module.css"

export function Acknowledge_Mobile () {

    return (
        <div className={display_mobile.mobile_sections}>

            {_bg_img()}

            <div className={display_mobile.mobile_section}>
                <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_title_text].join(' ')}>
                    <div className="title intro_title">
                        Acknowledgements
                    </div>
                </div>
                <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_content_text].join(' ')}>
                    <Acknowledge_Text />
                </div>
            </div>

        </div>
    );
}

