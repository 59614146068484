import React from 'react';

export function Acknowledge_Text() {

    return (
        <div className={"acknowledge_text"}>
            <p className={"descPara"}><span>I wish there were an easier and more conventional way to attribute
                                            assets to their originators.</span> So, to protest, I spent 6 hours creating
                this page to highlight how difficult and annoying it presently is.</p>

            <p className={"listPara"} style={{margin: "20px 55px 0 55px"}}>
                <ul>
                    <li>
                        <span style={{fontWeight: "bold"}}>The background image used on this page</span>
                        <ul>
                            <li style={{marginBottom: 0}}>Creator:&nbsp;
                                <a href={"https://www.freepik.com/author/mshakhor"}>https://www.freepik.com/author/mshakhor</a>
                            </li>
                            <li style={{marginBottom: 0}}>(March 2024)&nbsp;
                                <a href={"https://www.freepik.com/premium-vector/gray-background-with-white-pattern-that-says-i-love-you_47082385.htm"}>https://www.freepik.com/premium-vector/gray-background-with-white-pattern-that-says-i-love-you_47082385.htm</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                                                    <span
                                                        style={{fontWeight: "bold"}}>Lady with bag over her head</span>
                        <ul>
                            <li style={{marginBottom: 0}}>Creator: ??</li>
                            <li style={{marginBottom: 0}}>I downloaded this from a personal
                                blog, and after I did a google image search for the origin,
                                I couldn't find it. But, to clarify, this is not a picture
                                of me with a bag over my head; I'm way too hot for that.
                            </li>
                        </ul>
                    </li>
                    <li>
                                                    <span
                                                        style={{fontWeight: "bold"}}>Website title cursive text image</span>
                        <ul>
                            <li style={{marginBottom: 0}}>Creator: Font
                                generator (<a
                                href="https://www.textstudio.com/">https://www.textstudio.com</a>)</li>
                            <li style={{marginBottom: 0}}>Nifty tool. But, it does feel
                                stupid writing about it here. If I build a deck with a
                                hammer, do I really need to engrave the name of the hammer
                                manufacturer onto it?
                            </li>
                        </ul>
                    </li>
                    <li>
                                                    <span
                                                        style={{fontWeight: "bold"}}>PDF download icon image</span>
                        <ul>
                            <li style={{marginBottom: 0}}>Creator: No specific creator is
                                listed other than the platform itself. Weird and a little
                                ironic?
                            </li>
                            <li style={{marginBottom: 0}}>(March 2024)&nbsp;
                                <a href={"https://www.freepik.com/icon/download-pdf_5136979"}>https://www.freepik.com/icon/download-pdf_5136979</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                                                    <span
                                                        style={{fontWeight: "bold"}}>Prized poetic goldfish footer image</span>
                        <ul>
                            <li style={{marginBottom: 0}}>Creator:&nbsp;
                                <a href={"https://pnghut.com/user/noemska"}>https://pnghut.com/user/noemska</a>
                            </li>
                            <li style={{marginBottom: 0}}> (March 2024)&nbsp;
                                <a href={"https://pnghut.com/png/iZaDcZPJKh/goldfish-real-fish-transparent-image-transparent-png"}>https://pnghut.com/png/iZaDcZPJKh/goldfish-real-fish-transparent-image-transparent-png</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                                                    <span
                                                        style={{fontWeight: "bold"}}>Pencil site favicon image</span>
                        <ul>
                            <li style={{marginBottom: 0}}>Creator:&nbsp;
                                <a href={"https://www.flaticon.com/authors/icongeek26"}>https://www.flaticon.com/authors/icongeek26</a>
                            </li>
                            <li style={{marginBottom: 0}}> (March 2024)&nbsp;
                                <a href={"https://www.flaticon.com/free-icon/pencil_4585641"}>https://www.flaticon.com/free-icon/pencil_4585641</a>
                            </li>
                            <li style={{marginBottom: 0}}>The entire concept of attributing
                                work to an alias online is so strange. It implies the need
                                to compartmentalize aspects of your professional or artistic
                                reputation so that you can ditch it and start fresh at any
                                time. This seems to provide commentary about society in
                                general (as being composed principally of judgemental
                                assholes), and also the artist/creator themselves, who is
                                clearly completely fucking paranoid. Newsflash: Its a
                                picture of a pencil; a picture of one is really not as
                                controversial as what you can do with it.
                            </li>
                        </ul>
                    </li>

                    <li>
                                                    <span
                                                        style={{fontWeight: "bold"}}>Resume "selfie"</span>
                        <ul>
                            <li style={{marginBottom: 0}}>
                                Whether the photo was created by me, or my iPhone, or the
                                specific developer that created the filters I used on my iPhone is up for debate.
                                Whether this is actually a photo of me is also up for debate.
                            </li>
                        </ul>
                    </li>
                </ul>
            </p>
        </div>
    );

}