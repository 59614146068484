import React from 'react';
import display from "@jvm/common/both.common.module.css";
import {Acknowledge_Full} from "./full.component.acknowledge";
import {Acknowledge_Mobile} from "./mobile.component.acknowledge";

export default function Acknowledge () {

    return (
        <div>
            <div className={display.showOnMobileOnly}>
                <Acknowledge_Mobile />
            </div>
            <div className={display.showFullLayout}>
                <Acknowledge_Full />
            </div>
        </div>
    );
}

