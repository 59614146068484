// noinspection SpellCheckingInspection

// eslint-disable-next-line
import React, {ReactNode} from "react";
import "./full.common.module.css";
import imgBackground from "./bgimg.png";
import {createTheme} from "@mui/material";

export const _mui_theme = createTheme({
    components: {
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true,
            },
        },
    }
});

export function _bg_img() {

    return (
        <img alt="Mature mature jobless human seeks employment and alt text for this tag."
             src={imgBackground}
             style={{position: "fixed", top: 0, height: "100vh", width: "100vw", opacity: "100%", zIndex: "-1", objectFit: "cover"}} />
    );
}

export function _obf_email(text?: String) {

    if (text) {
        return (<a
            href='ma&#105;lto&#58;&#106;ennif%65r&#37;2Ev%2&#69;%6Dur%64oc&#104;&#64;%67mai&#108;%2Eco%6D'>{text}</a>);
    }

    return (<a
            href='ma&#105;lto&#58;&#106;ennif%65r&#37;2Ev%2&#69;%6Dur%64oc&#104;&#64;%67mai&#108;%2Eco%6D'>jenn&#105;fer&#46;v&#46;mur&#100;o&#99;h&#64;gm&#97;il&#46;c&#111;m</a>
    );
}

export function _obf_tel(text?: String) {

    if (text) {
        return (
            <a href='tel&#58;(%37&#55;&#56;&#37;&#50;&#57;&#32;6&#55;%3&#56;&#45;6978'>{text}</a>);
    }

    return (
        <a href='tel&#58;(%37&#55;&#56;&#37;&#50;&#57;&#32;6&#55;%3&#56;&#45;6978'>&#40;778&#41;&#32;678&#45;69&#55;8</a>);
}

export function _download_link(innerHTML: ReactNode) {
    return (
        <a href='Jennifer_Murdoch-Resume-__Why_Cant_I_Find_a_Job_.org__.pdf' download>
            {innerHTML}
        </a>
    );
}


