import React from 'react';
import {_bg_img, _download_link, _obf_email, _obf_tel} from "@jvm/common";
import Sticky from 'react-sticky-el';
import imgPdfDownload from "./pdfdownload.png";
import imgMe from "./me.jpg";
import {SecIntro} from "../../text/v1/sec_intro";
import {SecExperience} from "../../text/v1/sec_experience";
import {SecEducation} from "../../text/v1/sec_education";
import {SecEnvironmentPrefs} from "../../text/v1/sec_environment_prefs";
import {TOC_Resume} from "./toc_resume";

export function Resume_Full() {

    return (
        <div>

            {_bg_img()}

            <div className="flex-container">
                <div className="resume_headers">
                    <div className="resume_header">
                        <Sticky stickyClassName={"hideOnPrint"}>
                            <div className="header_loc">

                                <div className="title">
                                    <div>Resume / "CV"</div>
                                    {
                                        _download_link(<img className={"pdfDownloadResumeSec pdfDownloadHideOnPrint"}
                                                            alt={"Download PDF Version"} src={imgPdfDownload}
                                                            height={"36px"}/>)
                                    }
                                </div>
                                <div className="name">Jennifer Murdoch</div>

                                <div className="location">Victoria, British Columbia</div>
                                <div className="location">CANADA</div>

                                <img className="photo" src={imgMe} width="100px" alt="Jennifer Murdoch portrait"/>

                                <div className="contact_info_box">
                                    <div className="contact_info_header">CONTACT</div>
                                    <div className="contact_info contact_info_email">{_obf_email()}</div>
                                    <div className="contact_info">{_obf_tel()}</div>
                                </div>

                            </div>
                        </Sticky>
                    </div>

                    <div className="resume_header display_on_print fix_on_print">
                        <div className="header_loc">

                            <div className="title">Resume / "CV"</div>
                            <div className="name">Jennifer Murdoch</div>

                            <div className="location">Victoria, British Columbia</div>
                            <div className="location">CANADA</div>

                            <img className="photo" src={imgMe} width="100px" alt="Jennifer Murdoch portrait"/>

                            <div className="contact_info_box">
                                <div className="contact_info_header">CONTACT</div>
                                <div className="contact_info contact_info_email">{_obf_email()}</div>
                                <div className="contact_info">{_obf_tel()}</div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="sections">
                    <div className="spacer"></div>

                    <div className="section">
                        <div className="heading">
                            Table of Contents
                        </div>
                        <div className="content_col">
                            <div className="content_box">
                                <div className="blue_div"></div>
                                <div className="content">
                                    <TOC_Resume />
                                </div>
                            </div>
                            <div className="footer"></div>
                        </div>
                    </div>

                    <div id={"ref_intro"} className="section">
                        <div className="heading">
                            Introduction&nbsp;&nbsp;&nbsp;&nbsp;<span className={"showOnPrint"}>( <span
                            style={{textTransform: "capitalize", letterSpacing: "0.1em"}}>I need a job... BADLY.</span> )</span>
                        </div>
                        <div className="content_col">
                            <div className="content_box">
                                <div className="blue_div"></div>
                                <div className="content">
                                    <SecIntro/>
                                </div>
                            </div>
                            <div className="footer"></div>
                        </div>
                    </div>



                    <div id={"ref_empl"} className="section">
                        <div className="heading">Focuses <span>and</span> Experiences with Employment</div>
                        <div className="content_col">
                            <div className="content_box">
                                <div className="blue_div"></div>
                                <div className="content">
                                    <SecExperience/>
                                </div>
                            </div>
                            <div className="footer"></div>
                        </div>
                    </div>


                    <div className="end_spacer display_on_print"></div>
                    <div className="page_break display_on_print"></div>
                    <div className="spacer display_on_print"></div>


                    <div id={"ref_educ"} className="section">
                        <div className="heading">Education</div>
                        <div className="content_col">
                            <div className="content_box">
                                <div className="blue_div"></div>
                                <div className="content">
                                    <SecEducation/>
                                </div>
                            </div>
                            <div className="footer"></div>
                        </div>
                    </div>

                    <div id={"ref_cult"} className="section">
                        <div className="heading">Culture <span>and</span> Work Structure Requirements</div>
                        <div className="content_col">
                            <div className="content_box">
                                <div className="blue_div"></div>
                                <div className="content">
                                    <SecEnvironmentPrefs/>
                                </div>
                            </div>
                            <div className="footer"></div>
                        </div>
                    </div>

                    {/*<div id={"ref_lout"} className="section">*/}
                    {/*    <div className="heading">Employment Nightmares <span>and</span> "Learning Outcomes"</div>*/}
                    {/*    <div className="content_col">*/}
                    {/*        <div className="content_box">*/}
                    {/*            <div className="blue_div"></div>*/}
                    {/*            <div className="content">*/}
                    {/*                <SecStories/>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="footer"></div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="section">
                        <div className="heading" style={{textTransform: "none", letterSpacing: "0.04em"}}>Thank you for
                            your time! <br className={"display_on_print"}/>Please {_obf_email("contact me")} with
                            further questions and to set up a meeting...
                        </div>
                    </div>

                    {/*<div className="section">*/}
                    {/*    <div className="heading">If my Life was a Lifestyle</div>*/}
                    {/*    <div className="content_col">*/}
                    {/*        <div className="content_box">*/}
                    {/*            <div className="blue_div"></div>*/}
                    {/*            <div className="content">*/}
                    {/*                <SecEnvironmentPrefs />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="footer"></div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className="end_spacer" style={{position: "relative"}}></div>

                </div>

            </div>
        </div>
    );
}
