import React from 'react';
import {_bg_img} from "@jvm/common";
import {Acknowledge_Text} from "./acknowledge_text";

export function Acknowledge_Full () {

    return (
        <div>
            {_bg_img()}

            <div className="flex-container">

                <div className="resume_headers">
                    <div className="resume_header">

                        <div className="header_loc">

                            <div className="title">Acknowledgements</div>
                        </div>

                    </div>
                </div>

                <div className="sections">
                    <div className="spacer"></div>

                    <div className="section">
                        <div className="heading">
                            Creating this page took me longer than it likely took any of these artists to create these assets.
                        </div>
                        <div className="content_col">
                            <div className="content_box">
                                <div className="blue_div"></div>
                                <div className="content">
                                    <Acknowledge_Text />
                                </div>
                            </div>
                            <div className="footer"></div>
                        </div>
                    </div>

                    <div className="end_spacer" style={{position: "relative", height: "15px"}}></div>
                </div>
            </div>
        </div>
    );
}

