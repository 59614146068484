import React from 'react';
import "../../layout/v1/page_resume.css";

export function SecEnvironmentPrefs() {
    return (
        <div className={"cultPrefs"}>
            <ul className="ui--diamond">
                <li><h3 style={{paddingTop: 0}}>"Pros" / Requirements</h3>
                    <ol className={"ui--no_bullet"}>
                        <li><span><span style={{textTransform: "uppercase"}}>A culture</span> of unconstrained rigorous planning</span> around
                            every type of significant project
                            undertaken by the organization at the long-term, mid-term, and short-term levels. A plan may
                            be flexible, but it must be present, well thought-out and complete.
                        </li>
                        <li><span><span style={{textTransform: "uppercase"}}>A culture</span> of commitment to the consideration of diverse opinions</span> in
                            both planning and
                            plan implementation activities.
                        </li>
                        <li><span><span style={{textTransform: "uppercase"}}>A culture</span> of support surrounding the escalation (without penalty) of any “ethical” or
                            “conflict of interest” concerns</span> that may arise surrounding employment conditions,
                            operational practices, client/customer/consumer interests, or society in the broader
                            sense.
                        </li>
                        <li><span><span style={{textTransform: "uppercase"}}>A Human Resources (HR) Department</span> with operational and discretionary independence</span> that
                            may overrule the
                            board and directors/executive in serious matters regarding employee welfare.
                        </li>
                    </ol>
                </li>


                <div className="end_spacer display_on_print"></div>
                <div className="page_break display_on_print"></div>
                <div className="spacer display_on_print"></div>


                <li><h3>"Cons" / Aversions</h3>
                    <ol className={"ui--no_bullet"}>
                        <li><span><span
                            style={{textTransform: "uppercase"}}>Inefficiency</span> that goes unchecked</span>. If
                            individuals are not afforded the time and space to identify and creatively address
                            ineffective or inefficient ways in which things are done, the long-term consequences speak
                            for themselves.
                        </li>
                        <li><span><span style={{textTransform: "uppercase"}}>Intentional non-disclosure</span> and company secrets</span>.
                            If HR or operational processes do not support near-100% transparency with every employee at every
                            level, and with relevant external stakeholders, problems will inevitably arise in
                            various aspects of the business. Employee compensation may be a tough subject within the
                            organization, but it should be discussed respectfully and this warrants as much transparency
                            within the organization as possible.
                        </li>
                        <li><span><span style={{textTransform: "uppercase"}}>Negligent Disregard of employee welfare</span> when engaging in business planning activities</span>.
                            HR recruitment process may not practically be able to deliver a perfectly fair result in
                            every hiring, but it can and must be respectful of every applicant. The communicated
                            available bandwidth of employees must also be respected. This means either recruiting/hiring
                            new team members when a team is over committed or adjusting plans and processes accordingly.
                            It also means refusing to expand a team when the sole motivator is unjustifiable or
                            impractical “company growth”. I have yet to work for an organization where each
                            independent employee discusses with their manager and decides for themselves how and when
                            feedback or “performance reviews” are gathered and relayed, but I do believe employee
                            respect requires this level of non-automated human attention and supported autonomy.
                        </li>
                    </ol>
                </li>
            </ul>
        </div>
    );
}
