import React from 'react';
import display_mobile from "@jvm/common/mobile.common.module.css"
import {_bg_img, _obf_email} from "@jvm/common";
import {TOC_Resume} from "./toc_resume";
import {SecIntro} from "../../text/v1/sec_intro";
import {SecExperience} from "../../text/v1/sec_experience";
import {SecEducation} from "../../text/v1/sec_education";
import {SecEnvironmentPrefs} from "../../text/v1/sec_environment_prefs";

export function Resume_Mobile() {

    return (
        <div className={display_mobile.mobile_sections}>

            {_bg_img()}

            <div className={display_mobile.mobile_section}>
                <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_title_text].join(' ')}>
                    <div className="title intro_title">Table of Contents</div>
                </div>
                <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_subtitle_text].join(' ')}>
                    <p style={{margin: "10px 0 0px 20px"}}>
                        <TOC_Resume mobile={true} />
                    </p>
                </div>
            </div>

            <div id={"mobile_ref_intro"} style={{position: "absolute", marginTop: "-10px"}}/>
            <div className={display_mobile.mobile_section}>
                <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_title_text].join(' ')}>
                    <div className="title intro_title">
                        Introduction&nbsp;&nbsp;&nbsp;( <span
                        style={{textTransform: "capitalize", letterSpacing: "0.1em"}}>I need a job... BADLY.</span> )
                    </div>
                </div>
                <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_content_text].join(' ')}>
                    <SecIntro />
                </div>
            </div>

            <div id={"mobile_ref_empl"} style={{position: "absolute", marginTop: "-10px"}}/>
            <div className={display_mobile.mobile_section}>
                <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_title_text].join(' ')}>
                    <div className="title intro_title">Focuses <span>and</span> Experiences with Employment</div>
                </div>
                <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_content_text].join(' ')}>
                    <SecExperience mobile={true}/>
                </div>
            </div>

            <div id={"mobile_ref_educ"} style={{position: "absolute", marginTop: "-10px"}}/>
            <div className={display_mobile.mobile_section}>
                <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_title_text].join(' ')}>
                    <div className="title intro_title">Education</div>
                </div>
                <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_content_text].join(' ')}>
                    <SecEducation/>
                </div>
            </div>

            <div id={"mobile_ref_cult"} style={{position: "absolute", marginTop: "-10px"}}/>
            <div className={display_mobile.mobile_section}>
                <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_title_text].join(' ')}>
                    <div className="title intro_title">Culture <span>and</span> Work Structure Requirements</div>
                </div>
                <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_content_text].join(' ')}>
                    <SecEnvironmentPrefs/>
                </div>
            </div>

            <div className={display_mobile.mobile_section}>
                <div className={[display_mobile.mobile_intro_box, display_mobile.mobile_header_title_text].join(' ')}>
                    <div className="title intro_title">
                        <p>Thank you for your time!</p>
                        <p style={{textTransform: "none"}}>Please {_obf_email("contact me")} with
                            further questions and to set up a meeting...</p>
                    </div>
                </div>
            </div>

        </div>
    );
}