import React from 'react';
import display from "@jvm/common/both.common.module.css";
import {Resume_Mobile} from "./mobile.component.resume";
import {Resume_Full} from "./full.component.resume";

export function PageResume() {

    return (
        <div>
            <div className={display.showOnMobileOnly}>
                <Resume_Mobile />
            </div>
            <div className={display.showFullLayout}>
                <Resume_Full />
            </div>
        </div>
    );
}


