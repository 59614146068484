import React from 'react';
import "../../layout/v1/page_resume.css";

export function SecIntro() {
    return (
        <div>
            <p><span>I am looking for full-time employment, but I will also consider part-time and contract
                positions.</span></p>

            <p>I bring to the table a breadth of industry experience and skills that enables me to perform
                exceptionally well in a variety of roles. I also bring a dedication to thorough and quality
                communication and customer service, diligent project planning and documentation, illuminating research
                and discovery work, detailed requirements gathering and validation, and consistent and quality
                engagement with all relevant stakeholders.</p>

            {/*<p>I bring to the table a breadth of industry experience and skills that enables me to perform*/}
            {/*    exceptionally well in a variety of roles. What is common, however, across all of my previously-held positions*/}
            {/*    is a dedication to thorough and quality communication and customer service, diligent project planning*/}
            {/*    and documentation, illuminating research and discovery work, detailed requirements gathering and*/}
            {/*    validation, and consistent and quality engagement with all relevant stakeholders.</p>*/}

            <p>I have some physical issues with mobility and my back/neck, and so if a job is physically-demanding or
                requires extended periods of standing without a break, I may not be able to assume full-time hours. </p>

            <p><span>I have been
                unemployed for over 18 months (Since September of 2022) and my rental housing
                situation is extremely unstable as a direct result. I am in desperate need of employment. </span>
            </p>

            {/*<p>It is unfortunate that my job experience is littered with examples of antipatterns for solid*/}
            {/*    business operations, planning, and leadership. However, it has afforded me a certain breadth of*/}
            {/*    highly advantageous skills and also stubbornness with respect to how I believe a business should be run and*/}
            {/*    people should be treated.</p>*/}

            {/*<p>I cannot explain my prolonged bout of unemployment definitively because I myself do not*/}
            {/*    understand it; I consider my background across a wide variety of different fields and roles to*/}
            {/*    be highly advantageous. What I do know is that the software tech sector in Victoria is filled*/}
            {/*    with small investor-driven businesses with very shady practices that may be fairly described as*/}
            {/*    both unethical and illegal. One such operation fired me and offered me hush money for providing*/}
            {/*    evidence to their board of a wrongful dismissal of another employee and broader issues of a*/}
            {/*    discriminatory nature. Another fired me simply for putting a placeholder in my online calendar*/}
            {/*    for a regular but flexible lunch break. Another business I worked for forced me to quit after I*/}
            {/*    outed a team lead for showing inappropriate social media photos of a young job applicant around*/}
            {/*    the team. Yet another business forced me to quit after wrongfully accusing me of malicious*/}
            {/*    actions and intent that were unfounded. It was later revealed to me that the owner was unable to*/}
            {/*    pay me wages that were owed to me.</p>*/}


        </div>
    );
}