import React from 'react';
import {NavHashLink} from "react-router-hash-link";

export const TOC_Resume = ({mobile = false}) => {

    return (
        <div>
            <h3>Sections</h3>
            <ul className={"toc"}>
                <li><NavHashLink smooth to={mobile ? '#mobile_ref_intro' : '#ref_intro'}>Introduction</NavHashLink></li>
                <li><NavHashLink smooth to={mobile ? '#mobile_ref_empl' : '#ref_empl'}>Focuses <span>and</span> Experiences with
                    Employment</NavHashLink></li>
                <li><NavHashLink smooth to={mobile ? '#mobile_ref_educ' : '#ref_educ'}>Education</NavHashLink></li>
                <li><NavHashLink smooth to={mobile ? '#mobile_ref_cult' : '#ref_cult'}>Culture <span>and</span> Work Structure
                    Requirements</NavHashLink>
                </li>
                {/*<li><a href={"#ref_lout"}>Employment Nightmares <span>and</span> "Learning Outcomes"</a>*/}
                {/*</li>*/}
            </ul>
        </div>
    );
}