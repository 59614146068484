import React, {createRef, Suspense} from 'react';
import {createBrowserRouter, RouterProvider, useOutlet} from "react-router-dom";
import SiteIntro, {SiteFooter, SiteHeader} from "@jvm/site_intro";
import Resume from "@jvm/site_resume";
import Acknowledge from '@jvm/site_acknowledgements';
import {CSSTransition, SwitchTransition} from "react-transition-group";

const routes = [
    { path: '/acknowledge', name: 'Acknowledgements', element: <Acknowledge />, nodeRef: createRef() },
    { path: '/', name: 'Home', element: <Resume />, nodeRef: createRef() },
    { path: '/*', name: 'Default', element: <Resume />, nodeRef: createRef() },
]

function PageTemplate () {

    const currentOutlet = useOutlet();

    const location = window.location;
    const { nodeRef } =
    routes.find((route) => route.path === location.pathname) ?? {}

    return(
        <div>
            <SwitchTransition>
                <CSSTransition
                    key={location.pathname}
                    // @ts-ignore
                    nodeRef={nodeRef}
                    timeout={250}
                    classNames={'page'}
                    unmountOnExit
                    mountOnEnter
                >
                    {(state) => (
                        // @ts-ignore
                        <div ref={nodeRef} className="page">
                            <Suspense fallback={<div>Loading...</div>}>
                                <SiteHeader/>
                                <SiteIntro/>
                                {currentOutlet}
                                <SiteFooter/>
                            </Suspense>
                        </div>
                    )}

                </CSSTransition>
            </SwitchTransition>
        </div>

    );
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <PageTemplate />,
        children: routes.map((route) => ({
            index: route.path === '/',
            path: route.path === '/' ? undefined : route.path,
            element: route.element
        })),
    }
])

function App() {
    return (
        <RouterProvider router={router} />
    );
}

export default App;
