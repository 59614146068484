import React from 'react';
import "../../layout/v1/page_resume.css";
import "./resume_formatting.css"


export function SecEducation() {
    return (
        <div className={"edu"}>
            <ul style={{listStyleType: "none"}}>
                <li>
                    <h3><span>Post-Secondary Education</span></h3>
                    <ul className={"ui--diamond"}>
                        <li><span>Graduate Studies, Computer Science</span><br></br>University of Victoria; Victoria, BC, Canada
                            <ul>
                                <li>Research and writing/publication activities</li>
                                <li>Teaching and teaching assistance</li>
                                <li>Computer science outreach workshops</li>
                            </ul>
                        </li>
                        <li><span>Bachelors Degree, Computer Science (B.CSc.)</span><br></br>Dalhousie University; Halifax, NS, Canada
                            <ul>
                                <li>Multiple scholarships and awards</li>
                                <li>Heavily involved with the faculty's student society</li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <div className={"sec_spacer"} />
                <li>
                    <h3><span>Certifications</span></h3>
                    <ul>
                        <li><span style={{fontWeight: "bold"}}>Certified Associate of Project Management (CAPM)</span><br></br>(Project Management Institute)</li>
                        <li><span style={{fontWeight: "bold"}}>Serving It Right</span> (BC, Canada)<br></br>Instruction
                            on legal responsibilities when serving liquor</li>
                        <li><span style={{fontWeight: "bold"}}>Food Safe Level 1</span> (BC, Canada)<br></br>A food
                            handling, sanitation and work safety course designed for food service establishment operators and front line food service workers</li>
                    </ul>
                </li>
            </ul>
        </div>
    );
}

