import React from 'react';

export function Why_No_Job() {

    return (
        <div>
            <p><span>I have been unemployed since September of 2022.</span></p>

            <p>
                The last solid employment I had was for a company engaged in some 'sketchy'-at-best/'criminal'-at-worst
                behaviour. When I approached upper management about issues that were directly affecting my team, I was
                immediately fired and offered thousands in hush money. [I did not take the money.] I am unaware if this
                company could be bad-mouthing me among professional "peers", but I have inexplicably been unable to
                obtain new employment ever since. I have been forced to move apartments and take on personal debt that
                is irresponsible. In addition, I have significant back/neck injuries which intermittently cause
                significant mobility issues that prevent me from taking full-time physically-demanding roles.
            </p>

            <p>
                <span>I need work... badly.</span> I hope you will read my resume and decide for yourself that I do, in
                fact, possess a broad and sophisticated collection of skills and background of experience.
            </p>

            <p><span>Thank you for your time and consideration in reading onwards...</span></p>
        </div>
    );
}

export function What_Job_Do_You_Want() {

    return (
        <div>
            <p>
                My forward-looking interests lie somewhere at the intersection of
                technology, research, and communications. Unfortunately, it is not
                financially feasible for me at this time to go back to school to retrain
                in a new domain such as Journalism.
            </p>

            <p>
                Below is a sprinkling of the types of job roles/titles I would very much
                consider. It is not an exhaustive list.
            </p>

            <p><span>Examples of job titles</span>:</p>

            <p>
                <ul className={"job_title_list"}>
                    <li><span>News Journalist</span> (Magazine or Newspaper), Technical
                        Writer
                    </li>
                    <li>
                        <span>Software Development Manager</span>, <span>Product Manager</span>,
                        <span>Project Manager</span>, <span>Team Lead</span>,
                        <span>Software Developer</span>, Quality Assurance Analyst,
                        Data Scientist, <span>Data Analyst</span>
                    </li>
                    <li><span>Business Marketer</span>, Marketing Copy Writer, Event
                        Planner, <span>Office Assistant/Receptionist</span>, Administrator
                    </li>
                    <li><span>Customer Support Specialist</span>, <span>Product Training Specialist</span>,
                        Customer Service/Retail Sales/Hotel Associate
                    </li>
                    <li>Food and Beverage Server, Bartender, Restaurant Host, Prep Cook,
                        Dishwasher
                    </li>
                </ul>
            </p>

            <p>These are but some of the roles I would consider. <span>I welcome
                                            other ideas of how to propel myself forward and away from my present
                                            set of circumstances.</span></p>
        </div>
    );
}